<template>
    <div>
        <div class="container">
            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                        :active.sync="open_loader"
                        :can-cancel="false"
                        :is-full-page="true"
                        :color="'red'"
                >
                </loading>
            </div>
            <div class="row" style="margin-top: 20%;">
                <div class=" col-lg-12">
                    <div class="container" style="color: black;" >
                        <div class="row loginCard" style=" border-radius: 25px;">

                            <InfoCardElement/>
                            <div class="col-lg-6 loginCardElement">
                                <p class="h3" style="text-align: center;margin-top: 70px">
                                </p>
                                <p class="h4" style="text-align: center; padding: 20px 35px 0px 35px;">
                                    <span style="color: rgb(23 37 84);">Nova Lozinka</span>
                                    <br><br>
                                </p>
                                <p style="text-align: center;padding-right: 10%; padding-left: 10%;font-size: larger">
                                    Kreirajte novu lozinku za prijavu u sustav
                                </p>
                                <form class="form-horizontal" style="padding: 0 45px 0 45px">
                                    <br>

                                    <div :class="{'form-group': true}">

                                        <label
                                            for="password"
                                            class="control-label"
                                        >
                                            Upišite proizvoljnu lozinku*
                                        </label>

                                        <input
                                            id="password"
                                            type="password"
                                            class="form-control inputCss"
                                            name="password"
                                            v-model="password"
                                            required
                                            style="color: black"
                                        >

                                        <span
                                            v-if="errors && errors.password"
                                            class="help-block text-danger"
                                        >
                                                <strong>
                                                    {{errors.password[0] }}
                                                </strong>
                                            </span>

                                    </div>

                                    <div :class="{'form-group': true}">

                                        <label
                                            for="password_confirm"
                                            class="control-label"
                                        >
                                            Ponovite lozinku*
                                        </label>

                                        <input
                                            id="password_confirm"
                                            type="password"
                                            class="form-control inputCss"
                                            name="password_confirm"
                                            v-model="password_confirm"
                                            required
                                            style="color: black;"
                                        >

                                        <span
                                            v-if="errors && errors.password_confirm"
                                            class="help-block text-danger"
                                        >
                                                <strong>
                                                    {{errors.password_confirm[0] }}
                                                </strong>
                                            </span>

                                    </div>


                                    <div class="form-group">
                                        <div class="col-lg-12" style="text-align: center; ">
                                            <button
                                                type="submit"
                                                class="btn btn-xl btn-secondary registerButton"
                                                @click.prevent="reset"

                                            >
                                                <string>Promjeni</string>
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        style="display: flex;flex-direction: row; justify-items: center; text-align: center;align-items: center">
                                        <div style="width: 33.33%">
                                            <hr>
                                        </div>
                                        <p style=" text-align: center;width: 33.33%; padding-top: 10px;">
                                            ili
                                        </p>
                                        <div style="width: 33.33%">
                                            <hr>
                                        </div>
                                    </div>
                                    <p style="color:  #e13131; text-align: center; padding-top: 15px;">
                                    <span class="col-lg-12" style="text-align: center; ">
                                        <button
                                            type="submit"
                                            class="btn btn-xl btn-secondary registerButton"
                                            @click.prevent="login"

                                        >
                                            <strong>Vrati se nazad</strong>
                                        </button>
                                    </span>

                                    </p>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import InfoCardElement from "./InfoCardElement.vue";

export default {
name: "ResetPassword",
    components: {
        InfoCardElement,
    Loading
    },
    data: function() {
        return {
            password: null,
            password_confirm: null,
            errors: null,
            open_loader: false,
        }
    },
    methods:{
        login() {
            this.$router.push('/login')
        },
        reset(){
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "reset-password", {
                password: vm.password,
                password_confirm: vm.password_confirm,
                token: this.$route.params.token
            })
                    .then(response => {
                        if( response.data.success ){
                            vm.$router.push('/login')
                        } else {
                            vm.errors = response.data.messages
                        }
                        vm.open_loader = false
                    })
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                        vm.open_loader = false
                    } )
        }
    }
}
</script>

<style scoped>

label{
    margin-left: 6%;
}

</style>
